import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import ReactDOM from "react-dom";
import {
  loadTinyFaceDetectorModel,
  detectSingleFace,
  TinyFaceDetectorOptions,
  resizeResults,
  matchDimensions,
  draw,
  loadFaceLandmarkTinyModel,
} from "face-api.js";

const App = forwardRef(({ reference, display, setIsFaceVerified }) => {
  const [video, setVideo] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [detected, setDetected] = useState(false);
  const [camera, setCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!display) {
      setCamera(false);
    }
  }, [display]);

  useImperativeHandle(reference, () => ({
    startCamera() {
      start();
    },
  }));

  useEffect(() => {
    setVideo(videoRef.current);
    setCanvas(canvasRef.current);
    const startCam = setTimeout(() => {
      start();
    }, 5000);
    return clearTimeout(startCam);
  }, []);

  const start = async () => {
    try {
      await launchCamera();
      const recognition = makeRecognition();
      await recognition.init();
      recognition.start();
    } catch (err) {
      console.log(err, "ASSJASKA");
    }
  };

  const getFaceDetectorOptions = () =>
    new TinyFaceDetectorOptions({ inputSize: 224, scoreThreshold: 0.5 });

  const makeRecognition = () => {
    let ctx;

    const init = async () => {
      setLoading(true);
      await loadTinyFaceDetectorModel("/models");
      await loadFaceLandmarkTinyModel("/models");
      ctx = canvas.getContext("2d");
    };

    const start = async () => {
      await wait(0);
      // console.log(video,"VIDEOOOOOOOOOOOOOOOOOOOOOOOOOOO")
      if (video.readyState === 4) {
        const faces = await detectSingleFace(
          video,
          getFaceDetectorOptions()
        ).withFaceLandmarks(true);
        setLoading(false);
        if (faces) {
          setDetected(true);
          setIsFaceVerified(false);
          try {
            faces.detection._score = "";
            faces.detection._classScore = "";
          } catch (err) {
            console.log(err);
          }

          const dims = matchDimensions(canvas, video, true);
          const resizedResults = resizeResults(faces, dims);
          if (true) {
            draw.drawDetections(canvas, resizedResults);
          }
          // if (true) {
          //   draw.drawFaceLandmarks(canvas, resizedResults);
          // }
        } else {
          setDetected(false);
          setIsFaceVerified(true);
          ctx.clearRect(0, 0, video.videoWidth, video.videoHeight);
        }
      }
      start();
    };

    return { init, start };
  };

  const launchCamera = () =>
    new Promise((resolve) => {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            mandatory: {
              minWidth: 320,
              maxWidth: 320,
              minHeight: 240,
              maxHeight: 240,
              minFrameRate: 1,
              maxFrameRate: 10,
            },
          },
        })
        .then(
          (stream) => {
            video.srcObject = stream;
            video.play();
            setCamera(true);
            resolve();
          },
          () => {}
        );
    });

  return (
    <div>
      {/* {!camera && (
        <button
          style={{
            padding: 20,
            fontSize: 14
          }}
          onClick={() => {
            start();
          }}
        >
          Launch Camera
        </button>
      )} */}
      <div
        style={{
          display: camera ? "" : "none",
        }}
      >
        <video
          style={{ position: "absolute", bottom: 10, right: 10 }}
          ref={videoRef}
        />
        <canvas
          style={{ position: "absolute", bottom: 10, right: 10 }}
          ref={canvasRef}
        />
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
            width: 320,
            height: 240,
            background: "rgba(0,0,0,0.5)",
            zIndex: 1,
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Loading
        </div>
      )}
      {/* {camera && <h2>Face Detected : {detected ? "True" : "False"}</h2>} */}
    </div>
  );
});

const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));

export default App;
