// Pages Elements Split into 7 Parts Find it By Find

// Sections Available :

// Npm Packages
import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import { toast } from "react-toastify";
import { CCol, CModal, CModalBody } from "@coreui/react";
import CameraFeed from "./camera-feed";
// Hooks
import useResponsive from "../../hooks/useResponsive";
import FaceDetection from "./FaceDetection";
// import { FaceFinder } from "react-picojs";
// import "react-picojs/dist/index.css";

// Component or Elements
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { ExportCSV } from "./ExportCSV";
// Styles
import "./StudentAssignment.css";

// Icons
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineFileAdd } from "react-icons/ai";

// Assets
import CompletedTick from "../../Assets/OnceUpon.png";

// Custom Components
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import KRequiredLabel from "./common/KRequiredLabel";
import KEditor from "./common/KEditor";
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import Danger from "./Toastify/Danger";
import Timer from "./Timer";

import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

// Services
import {
  getAllStudentAssignments,
  getAssignmentQuestion,
  postAnswersByStudent,
  postSingleAnswerByStudent,
  getStudentPreviouslyAnsweredQuestion,
  ExamSubmissionByStudent,
} from "../../services/Apiservices";

const StudentAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
}) => {
  // Initialization
  // Hooks Initialization
  const { isBigScreen } = useResponsive();
  const navigate = useNavigate();

  // console.log(moment().format("YYYY-MM-DD"),"QQQQQQQQQQQQQQ");
  let startTime = moment().format('HH:mm:ss');
  // console.log(moment(startTime ,'hh:mm A').add(25,'minutes').format('hh:mm A'),"QQQQQQQQQQQQQQ");

  // SetState Initialization
  const [csvStructure, setCsvStructure] = useState([]);

  const counterRef = useRef(null);

  const singeQuestionTimerRef = useRef(null);
  const FaceDetectionRef = useRef();

  const [answerEditor, setAnswerEditor] = useState([]);
  const [draftCollection, setDraftCollection] = useState([]);
  const [responseCollection, setResponseCollection] = useState([]);

  const [tableAssignmentDisplay, setTableAssignmentDisplay] = useState([]);
  const [lastAssignment, setlastAssignment] = useState({});
  const [selectedAssignment, setSelectedAssignment] = useState("");
  const [assignmentName, setAssignmentName] = useState({
    subjectName: "",
    ExamName: "",
    examDate: "",
  });
  const [assessmentCheckbox, setAssessmentCheckbox] = useState(false);

  const [questionDisplay, setQuestionDisplay] = useState([]);
  const [videodisplay, setVideoDisplay] = useState(true);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [examInstruction, setExamInstruction] = useState("");

  const [showIntructions, setShowIntructions] = useState(true);

  const [completedTest, setCompletedTest] = useState(false);

  const [isTimeout, setTimeout] = useState(false);

  const [questionIdISDisplaying, setQuestionIdISDisplay] = useState("");

  const RadioButtonAnswerHandler = async (key, value, AssignmentId, data) => {
    const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
    const removeExistingKey = answerEditor.filter((k) => k._id !== data._id);
    try {
      const bonuspoint =
        value === data.correctAnswer
        ? SingleQuestiontimer >= 20
          ? 3
          : SingleQuestiontimer >= 10
          ? 2
          : SingleQuestiontimer >= 5
          ? 1
          : 0
        : 0;
      const questionpoint = value === data.correctAnswer ? 10 : 0;
      const body = {
        studentId: userCreds.user._id,
        assignmentId: AssignmentId,
        questionId: data._id,
        correctAnswer: value,
        isCorrect: value === data.correctAnswer ? true : false,
        timer: SingleQuestiontimer,
        marks: questionpoint,
        bonusMark: bonuspoint,
        total: questionpoint + bonuspoint,
      };
      const response = await postSingleAnswerByStudent(body);
      if (response.success) {
        setAnswerEditor([
          ...removeExistingKey,
          {
            ...body,
            ...data,
            key: key,
            value: value,
            total: questionpoint + bonuspoint,
            isCorrect: value === data.correctAnswer ? true : false,
            ignored: false,
          },
        ]);
        if (questionIndex + 1 === questionDisplay.length) {
          // SubmitExamFunc(selectedAssignment, userCreds.user._id, true);
        } else {
          const nextAuestion = questionIndex + 1;
          setQuestionIdISDisplay(questionDisplay[nextAuestion]._id);
          setQuestionIndex(nextAuestion);
        }
      } else {
        toast.error(<Danger body={response?.error} />);
      }
    } catch (err) {
      console.log(err, "READIOERROR");
    }
  };

  const RadioButtonAnswerHandlerTimeout = async () => {
    const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
    const removeExistingKey = answerEditor.filter(
      (k) => k._id !== questionIdISDisplaying
    );

    try {
      const body = {
        studentId: userCreds.user._id,
        assignmentId: selectedAssignment,
        questionId: questionIdISDisplaying,
        correctAnswer: "",
        isCorrect: false,
        timer: SingleQuestiontimer,
        marks: 0,
        bonusMark: 0,
        total: 0,
      };
      const response = await postSingleAnswerByStudent(body);
      if (response) {
        if (response.success) {
          setAnswerEditor([
            ...removeExistingKey,
            {
              ...body,
              key: questionIdISDisplaying,
              value: "",
              _id: questionIdISDisplaying,
              total: 0,
              isCorrect: false,
              ignored: true,
            },
          ]);
          if (questionIndex + 1 === questionDisplay.length) {
            SubmitExamFunc(selectedAssignment, userCreds.user._id, true);
          } else {
            const nextAuestion = questionIndex + 1;
            setQuestionIdISDisplay(questionDisplay[nextAuestion]._id);
            setQuestionIndex(nextAuestion);
          }
        } else {
          if (
            answerEditor.filter((k) => k._id === questionIdISDisplaying)
              .length > 0
          ) {
            const nextAuestion = questionIndex + 1;
            setQuestionIdISDisplay(questionDisplay[nextAuestion]._id);
            setQuestionIndex(nextAuestion);
          }
        }
      }
    } catch (err) {
      console.log(err, "READIOERROR");
    }
  };

  // console.log("AOSJAOJSJSA", questionIdISDisplaying, answerEditor);
  const getTimes = (date, startTime) => {
    const dd = moment(date + " " + startTime);
    const momentDuration = moment.duration(dd.diff(moment()));
    return `
  ${parseInt(momentDuration.days())} Days
    ${parseInt(momentDuration.hours())} Hrs
    ${parseInt(momentDuration.minutes())} Mins
    ${parseInt(momentDuration.seconds())} Secs
  `;
  };

  const AnswerEditorHandler = (key, value, data) => {
    const previousData = answerEditor.find((k) => k.key === key);
    const removeExistingKey = answerEditor.filter((k) => k.key !== key);
    if (data !== undefined) {
      setAnswerEditor([
        ...removeExistingKey,
        {
          key: key,
          value: value,
          ...data,
        },
      ]);
    } else {
      setAnswerEditor([
        ...removeExistingKey,
        {
          ...previousData,
          key: key,
          value: value,
        },
      ]);
    }
  };

  const AnswerCollectionHandler = (key, value, isDraft, toRemove) => {
    const removeDraftCollection = draftCollection.filter(
      (item) => item.key !== key
    );
    const removeResponseCollection = responseCollection.filter(
      (item) => item.key !== key
    );

    const response = {
      key: key,
      ...value,
    };

    if (toRemove) {
      setResponseCollection(removeResponseCollection);
      setDraftCollection(removeDraftCollection);
    } else {
      if (isDraft) {
        setDraftCollection([...removeDraftCollection, response]);
        setResponseCollection(removeResponseCollection);
      } else {
        setDraftCollection(removeDraftCollection);
        setResponseCollection([...removeResponseCollection, response]);
      }
    }
  };

  var entities = {
    amp: "&",
    apos: "'",
    "#x27": "'",
    "#x2F": "/",
    "#39": "'",
    "#47": "/",
    lt: "<",
    gt: ">",
    nbsp: " ",
    quot: '"',
  };
  function decodeHTMLEntities(text) {
    return text.replace(/&([^;]+);/gm, function (match, entity) {
      return entities[entity] || match;
    });
  }

  const SubmitExamFunc = async (assignId, userId, value) => {
    let bod = {
      assignmentId: assignId,
      studentId: userId,
      submitted: value,
      lastSubmittedDate: moment().format("YYYY-MM-DD"),
      lastSubmittedTime: moment(startTime ,'hh:mm A').add(25,'minutes').format('hh:mm A')
    };

    setCompletedTest(true);
    setVideoDisplay(false);
    const response = await ExamSubmissionByStudent(bod);

    if (response.success === true) {
      setVideoDisplay(false);
      setCompletedTest(true);
      setVideoDisplay(false);
    }
  };

  const getStudentPreviouslyAnsweredQuestionList = async (body) => {
    const response = await getStudentPreviouslyAnsweredQuestion(body);
    if (response) {
      if (response.success) {
        const data = response.data.map((x) => ({
          ...x,
          bonusMark: Number(x.bonusMark),
          total: Number(x.total),
          marks: Number(x.marks),
          timer: Number(x.timer),
          key: x.questionId,
          value: x.correctAnswer,
          _id: x.questionId,
          ignored: x.correctAnswer === "" ? true : false,
        }));

        // alert(questionDisplay.length)
        // alert(response.data.length)
        if(questionDisplay.length === response.data.length){
          setAssessmentCheckbox(true)
        }
        setAnswerEditor(data);
        console.log(response, "DSDSSDDDSDDSDSRRERE");
      }
    }
  };

  const studentansweredFunc = async (assignID, staffID) => {
    return 1;
    // const body = {
    //   assignmentId : assignID,
    //   staffId : staffID,
    //   }
    //   const assignmentQuestion = await getAssignmentQuestion(body);
    //   if(assignmentQuestion.success) {
    //     let studData = {
    //       studentId: JSON.parse(localStorage.userCreds).user._id,
    //       assignmentId:assignID,
    //     }
    //     const response = await getStudentPreviouslyAnsweredQuestion(body)
    //     console.log(response,"QQQQQQQQQQQQQQQQQQQQQQQQQQ");
    //   }
  };

  const getAssignmentQuestions = async (data) => {
    try {
      const userCreds = await JSON.parse(localStorage.getItem("userCreds"));

      const { _id } = data;
      const body = {
        assignmentId: _id,
        staffId: data.staffId,
      };
      const assignmentQuestion = await getAssignmentQuestion(body);
      if (assignmentQuestion) {
        if (assignmentQuestion.success) {
          setQuestionDisplay(assignmentQuestion.data);
          try {
            if (assignmentQuestion.data.length > 0) {
              setQuestionIdISDisplay(assignmentQuestion.data[0]._id);
            }
          } catch (err) {
            console.error(err);
          }
          // console.log(assignmentQuestion.data, "DAJOJOAJK");
          getStudentPreviouslyAnsweredQuestionList({
            studentId: userCreds.user._id,
            assignmentId: _id,
          });
          setExamInstruction(data.instruction);
          setExamEndTime(data.examEndTime);
          setExamStartTime(data.examStartTime);
          try {
            const userCreds = await JSON.parse(
              localStorage.getItem("userCreds")
            );
            const csvStructureData = assignmentQuestion.data.map((x) => [
              userCreds.userName,
              _id,
              x._id,
              decodeHTMLEntities(String(x.question).replace(/<(.|\n)*?>/g, "")),
              x.answerType === "RadioButton"
                ? x.options.reduce((prev, next, index) => {
                    if (index === 1) {
                      return `${index}) ${decodeHTMLEntities(
                        String(prev.option).replace(/<(.|\n)*?>/g, "")
                      )}\n ${index + 1}) ${decodeHTMLEntities(
                        String(next.option).replace(/<(.|\n)*?>/g, "")
                      )}`;
                    }
                    return `${prev}\n ${index + 1} ${decodeHTMLEntities(
                      String(next.option).replace(/<(.|\n)*?>/g, "")
                    )}`;
                  })
                : "",
              "",
            ]);

            setCsvStructure([
              [
                [
                  "StudentId",
                  "AssignmentId",
                  "questionId",
                  "Question",
                  "Options (Note : Kindly Type the Option Number in Answer Field ) ",
                  "Answer",
                ],
                ...csvStructureData,
              ],
            ]);
          } catch (err) {
            console.log(err, "CSVSTRUCTURE");
          }

          setSelectedAssignment(_id);
        } else {
          toast.error(<Danger body={assignmentQuestion?.error} />);
        }
      }
    } catch (err) {
      console.log(err, "ASASSASASASCv");
    }
  };
  const [examNotAvailable, setExamNotAvailable] = useState(false);

  const fetchData = async () => {
    setTableAssignmentDisplay([]);
    const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
    if (userCreds) {
      try {
        const body = {
          ageGroup: userCreds.user.grade,
        };
        const studentAssignment = await getAllStudentAssignments(
          body,
          userCreds.token
        );
        if (studentAssignment.success) {
          if (studentAssignment.data) {
            setTableAssignmentDisplay(studentAssignment.data);
            if(studentAssignment?.lastAssignment !== null && studentAssignment?.lastAssignment !== undefined){
              setlastAssignment(studentAssignment?.lastAssignment)
            }
            // console.log(studentAssignment?.lastAssignment,"sssssssssssssssss");
            
            if (studentAssignment.data.length > 0) {
              const tableData = studentAssignment.data[0];
              getAssignmentQuestions(tableData);
              setAssignmentName({
                subjectName: tableData.katbookSubjectName,
                ExamName: tableData.examName,
                examDate: tableData.examDate,
              });

              setExamNotAvailable(false);
            } else {
              setExamNotAvailable(true);
            }
          }
        } else {
          // toast.error(<Danger body={studentAssignment.error} />);
          setExamNotAvailable(true);
        }
      } catch (error) {
        console.log(error, "USER_CREDENTIALS_RESPONSE");
      }
    }
  };

  useEffect(() => {
    // alert()
    if (localStorage.length > 0) {
      fetchData();
    } else {
      const goToHomePage = () => navigate("/login");
      goToHomePage();
    }
  }, [localStorage]);

  const [timer, setTimer] = React.useState(3600);
  const [SingleQuestiontimer, setSingleQuestiontimer] = React.useState(30);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const settingTimer = React.useCallback(() => {
    setTimer((timer) => {
      if (timer == 0) {
        if (completedTest) {
        } else {
          setCompletedTest(true);
          setVideoDisplay(false);
          SubmitExamFunc(
            selectedAssignment,
            JSON.parse(localStorage.userCreds).user._id,
            true
          );
        }
        return 0;
      } else {
        return timer - 1;
      }
    });
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const singleQuestionsettingTimer = useCallback(() => {
    setSingleQuestiontimer((time) => {
      if (time === 0) {
        if (timer > 30) {
          return 30;
        } else {
          return timer;
        }
      } else {
        if (timer > 30) {
          return time - 1;
        } else {
          return timer;
        }
      }
    });
  });

  // fUNCTION cALL fOR tIMEOUT
  useEffect(() => {
    setSingleQuestiontimer(() => {
      if (timer > 30) {
        return 30;
      } else {
        return timer;
      }
    });
  }, [questionIdISDisplaying]);
  // tIMER FOR qUESTION
  React.useEffect(() => {
    if (SingleQuestiontimer === 0) {
      RadioButtonAnswerHandlerTimeout();
    }
  }, [SingleQuestiontimer]);

  const [examEndTime, setExamEndTime] = useState("");
  const [examStartTime, setExamStartTime] = useState("");

  const Gettimer = (examStartTime, examEndTime) => {
    var currentTime = moment().format("LTS");
    var startTime = moment(currentTime.toString(), "HH:mm:ss a");
    var endTime = moment(examEndTime, "HH:mm:ss a");
    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));
    // duration in hours
    var hours = parseInt(duration.asHours());
    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;
    console.log(
      parseInt(
        moment
          .duration(
            moment(examStartTime, "HH:mm:ss a").diff(
              moment(moment().format("LTS").toString(), "HH:mm:ss a")
            )
          )
          .asMinutes()
      ) %
        60 <
        0,
      "HAOHSHASSH"
    );
    if (hours < 0 || minutes < 0) {
      setCompletedTest(true);
      setVideoDisplay(false);
    } else {
      setTimer(minutes * 60 + hours * 3600 + 60);
    }
  };

  const logoutfunc = async () => {
    const goToHomePage = () => navigate("/login");
    goToHomePage();
  };

  const [isFaceVerified, setIsFaceVerified] = useState(false);
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    // Connect to a seaweedfs instance
  };

  return (
    <div onClick={() => disableOnOuterTouch()}>
      {/* Header Section Starts  */}

      {selectedAssignment == "" && (
        <div className="Navbar">
          <Header
            showProfile={showProfile}
            // profileIcon={KpIcon}
            // username={state.adminName ? state.adminName : state.user}
            falseShowProfile={falseShowProfile}
            notofShowProfile={notofShowProfile}
            // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
            personName={
              localStorage.length > 0
                ? JSON.parse(localStorage.userCreds).user.userTypeId.userType ==
                  "ADMIN"
                  ? JSON.parse(localStorage.userCreds).user.authorizedPerson
                  : JSON.parse(localStorage.userCreds).user.fullName
                : ""
            }
            ShowNotifications={ShowNotifications}
            userName={
              localStorage.length > 0
                ? JSON.parse(localStorage.userCreds).user.userName
                : ""
            }
            showNotification={showNotification}
            falseShowNotification={falseShowNotification}
            userType={
              localStorage.length > 0
                ? JSON.parse(localStorage.userCreds).user.userTypeId.userType
                : ""
            }
            userData={
              localStorage.length > 0 ? JSON.parse(localStorage.userCreds) : ""
            }
          />
        </div>
      )}
      {/* Header Section Ends */}

      <div
        style={{
          marginTop: selectedAssignment == "" ? "100px" : "auto",
        }}
        // className={selectedAssignment == "" ? "Dash_home_QWI" : ""}
      >
        <div
          className={`myattendcard2w ${
            selectedAssignment == "" ? "p-3" : "paddingTop-10"
          }`}
          style={{
            height:
              selectedAssignment == ""
                ? window.innerHeight - 117 + "px"
                : "100vh",
            overflowY: "auto",
          }}
        >
          {/* <QuestionView condition={selectedAssignment == "" ?true :false } window={window}> */}

          {/* Assessment Table Starts */}
          {selectedAssignment == "" && (
            <div
              style={{
                height:
                  selectedAssignment == ""
                    ? window.innerHeight - 250 + "px"
                    : "100vh",
                overflowY: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1>
                {examNotAvailable ? "Exam not available" : "Please wait ..."}
              </h1>
              {/* <KHeader
          style={{
            justifyContent: "space-between"
          }}
            frontElement={
              selectedAssignment !== ""
              &&
              <div
                  style={{
                    marginTop: "26px",
                    cursor : "pointer"
                }}
                onClick={() => {setSelectedAssignment("")}}
                >
                <IoIosArrowBack size={25}  />
          </div>}
            title={`Exams - ${moment().format("DD MMM YYYY")}`}
            containerClassName={`marginTop-20 justify-content-front`}
            LastElement={<div></div>}
          /> */}
              {/* <div className="marginLeft-20 marginRight-20 paddingLeft-10">
            <table 
                
                style={{
                    borderCollapse : "separate",
                    borderSpacing : "0px 5px"
                }}
            className="w-100 StudentAssignment__table">


              <tr
                style={{
                  border: "1px solid #E6ECF1",
                }}
              >
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"S.No"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Subject"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Exam Name"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Exam By Teacher"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Total Marks"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Exam Date"}
                    isRequired={false}
                  />
                    </th>
                      <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Exam Time"}
                    isRequired={false}
                  />
                  </th>
              </tr>

              {
                tableAssignmentDisplay.length > 0 ?
                tableAssignmentDisplay.map((tableData, index) => (
                    <tr 
                      onClick={() => {
                        getAssignmentQuestions(tableData)
                        setAssignmentName({
                          subjectName: tableData.katbookSubjectName,
                          ExamName : tableData.examName,
                          examDate : tableData.examDate,

                        })
                      }}
                className="py-3 StudentAssignment__tableElements"
                style={{
                  border: "1px solid #E6ECF1",
                      }}
                      
                 
                  >
                    <td className="p-2 StudentAssignment__tableStart">
                      {index + 1}
                    </td>
                    <td className="p-2 StudentAssignment__tableStart">
                      {tableData.katbookSubjectName}
                    </td>
                    <td className="p-2">{tableData.examName}</td>
                    <td className="p-2">{tableData?.staffName ? tableData?.staffName : ""}</td>
                      <td className="p-2">{tableData.totalMarks}</td>
                      <td className="p-2">
                        {
                    (tableData.examDate === null || 
                    tableData.examDate === undefined) ? "Not Assigned" : moment(tableData.examDate).format("DD/MMM/YYYY")
                    }
                       </td>
                    <td className="p-2 StudentAssignment__tableEnd">{
                    (tableData.examStartTime === null || 
                    tableData.examStartTime === undefined) ? "Not Assigned" : `${moment(tableData.examDate+" "+tableData.examStartTime).format("hh:mm A")} - ${moment(tableData.examDate+" "+tableData.examEndTime).format("hh:mm A")}`
                    }</td>
                   
                </tr>
                ))
                :
                <tr>
                  <td colspan="10">Exam not available</td>
                </tr>
              }

            </table>
              </div> */}
            </div>
          )}
          {/* Assessment Table Ends */}

          {selectedAssignment !== "" && (
            <Fragment>
              <Fragment>
                {selectedAssignment !== "" && showIntructions ? (
                  <div className="p-3 d-flex flex-column h-100 justify-content-between">
                    <div
                      className="smooth-scroll"
                      style={{
                        fontSize: "20px",
                        fontFamily: "Nunito",
                        height: "76%",
                        overflowY: "auto",
                      }}
                    >
                      {/* {parse(examInstruction)} */}
                      <h4>
                        <u>General Instructions :</u>
                      </h4>
                      <ol>
                        {/* <li className={"marginTop-10"}>
                          Total Duration of Exam is {"25 Minutes"}
                        </li> */}
                        <li className={"marginTop-10"}>
                          Quiz type: Online MCQ
                        </li>
                        <li className={"marginTop-10"}>
                          Quiz Duration: 5min
                        </li>
                        <li className={"marginTop-10"}>
                          Total of 10 multiple-choice questions in this mock-quiz (for the final quiz there will be 50 MCQs). The participant will get a maximum of 30 seconds to answer each question.
                        </li>
                        <li className={"marginTop-10"}>
                          Accuracy and speed will fetch more marks.
                        </li>
                        <li className={"marginTop-10"}>
                          The quiz questions will be only from the book allocated to a particular grade-category.
                        </li>
                        <li className={"marginTop-10"}>
                          Parents are not allowed to help their children with the answers during the competition.
                        </li>
                        <li className={"marginTop-10"}>
                          Please make sure that your internet speed is reasonably good.
                        </li>
                        <li className={"marginTop-10"}>
                          The exam needs to be taken up on a desktop computer or on a laptop.
                        </li>
                      </ol>
                    </div>
                    <div
                      className="border-d8d8d8 p-3"
                      style={{
                        fontFamily: "Nunito",
                        fontSize: "18px",
                        marginBottom: "30px"
                      }}
                    >
                      <span>
                        <input
                          onClick={() => {
                            setAssessmentCheckbox(!assessmentCheckbox);
                          }}
                          checked={assessmentCheckbox}
                          type="checkbox"
                        />
                        &nbsp;&nbsp;
                      </span>
                      I have read and Understood the Instructions. All computer
                      hardware alloted to me are in proper working condition. I
                      declare that I am not in possession of /not wearing/not
                      carrying any prohibited gadget like mobile phones
                      bluetooth devices etc. /any prohibited material with me
                      into the Examination Hall. I agree that in case of not
                      adhering to the instructions . I shall be liable to be
                      debarred from this Test and/or to disciplinary action,
                      which may include ban from future Tests/Examination
                      <div className="d-flex justify-content-end pt-2">
                        <KButton
                          btnColor={
                            // parseInt(
                            //   moment
                            //     .duration(
                            //       moment(examStartTime, "HH:mm:ss a").diff(
                            //         moment(
                            //           moment().format("LTS").toString(),
                            //           "HH:mm:ss a"
                            //         )
                            //       )
                            //     )
                            //     .asHours()
                            // ) > 0 ||
                            // parseInt(
                            //   moment
                            //     .duration(
                            //       moment(examStartTime, "HH:mm:ss a").diff(
                            //         moment(
                            //           moment().format("LTS").toString(),
                            //           "HH:mm:ss a"
                            //         )
                            //       )
                            //     )
                            //     .asMinutes() + 1
                            // ) %
                            //   60 >
                            //   0
                            //   ? "rgb(102, 102, 102)"
                            //   : parseInt(
                            //       moment
                            //         .duration(
                            //           moment(examEndTime, "HH:mm:ss a").diff(
                            //             moment(
                            //               moment().format("LTS").toString(),
                            //               "HH:mm:ss a"
                            //             )
                            //           )
                            //         )
                            //         .asHours()
                            //     ) < 0 ||
                            //     parseInt(
                            //       moment
                            //         .duration(
                            //           moment(examEndTime, "HH:mm:ss a").diff(
                            //             moment(
                            //               moment().format("LTS").toString(),
                            //               "HH:mm:ss a"
                            //             )
                            //           )
                            //         )
                            //         .asMinutes() + 1
                            //     ) %
                            //       60 <
                            //       0
                            !assessmentCheckbox ? "rgb(102, 102, 102)" : "green"
                          }
                          className={"ExamBtn"}
                          onClick={() => {
                            setShowIntructions(false);
                            // Gettimer(examStartTime, examEndTime);
                            setTimer(300);
                            try {
                              FaceDetectionRef.current.startCamera();
                              setVideoDisplay(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          isDisabled={
                            
                            assessmentCheckbox && lastAssignment !== null && lastAssignment !== undefined && lastAssignment?.lastSubmittedDate === moment().format("YYYY-MM-DD")? parseInt(
                              moment
                                .duration(
                                  moment(lastAssignment.lastSubmittedTime, "HH:mm:ss a").diff(
                                    moment(
                                      moment().format("LTS").toString(),
                                      "HH:mm:ss a"
                                    )
                                  )
                                )
                                .asHours()
                            ) > 0 ||
                            parseInt(
                              moment
                                .duration(
                                  moment(lastAssignment.lastSubmittedTime, "HH:mm:ss a").diff(
                                    moment(
                                      moment().format("LTS").toString(),
                                      "HH:mm:ss a"
                                    )
                                  )
                                )
                                .asMinutes() + 1
                            ) %
                              60 >
                              0
                              ? true
                              : parseInt(
                                  moment
                                    .duration(
                                      moment("11:59 PM", "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asHours()
                                ) < 0 ||
                                parseInt(
                                  moment
                                    .duration(
                                      moment("11:59 PM", "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asMinutes() + 1
                                ) %
                                  60 <
                                  0
                            ? true
                            : false : assignmentName.examDate === moment().format("YYYY-MM-DD") && assessmentCheckbox ? 
                            parseInt(
                              moment
                                .duration(
                                  moment(examStartTime, "HH:mm:ss a").diff(
                                    moment(
                                      moment().format("LTS").toString(),
                                      "HH:mm:ss a"
                                    )
                                  )
                                )
                                .asHours()
                            ) > 0 ||
                            parseInt(
                              moment
                                .duration(
                                  moment(examStartTime, "HH:mm:ss a").diff(
                                    moment(
                                      moment().format("LTS").toString(),
                                      "HH:mm:ss a"
                                    )
                                  )
                                )
                                .asMinutes() + 1
                            ) %
                              60 >
                              0
                              ? true
                              : parseInt(
                                  moment
                                    .duration(
                                      moment(examEndTime, "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asHours()
                                ) < 0 ||
                                parseInt(
                                  moment
                                    .duration(
                                      moment(examEndTime, "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asMinutes() + 1
                                ) %
                                  60 <
                                  0
                            ? true
                            : false : true
                          }
                          label={
                            lastAssignment !== null && lastAssignment !== undefined && lastAssignment?.lastSubmittedDate === moment().format("YYYY-MM-DD") ? parseInt(
                              moment(
                                moment(lastAssignment.lastSubmittedDate).format(
                                  "YYYY-MM-DD"
                                )
                              ).diff(moment().format("YYYY-MM-DD"), "days")
                            ) > 0
                              ? getTimes(lastAssignment.lastSubmittedDate, lastAssignment.lastSubmittedTime)
                              : (moment().format("YYYY-MM-DD") ==
                              lastAssignment.lastSubmittedDate &&
                                  parseInt(
                                    moment
                                      .duration(
                                        moment(
                                          lastAssignment.lastSubmittedTime,
                                          "HH:mm:ss a"
                                        ).diff(
                                          moment(
                                            moment().format("LTS").toString(),
                                            "HH:mm:ss a"
                                          )
                                        )
                                      )
                                      .asHours()
                                  ) > 0) ||
                                parseInt(
                                  moment
                                    .duration(
                                      moment(lastAssignment.lastSubmittedTime, "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asMinutes() + 1
                                ) %
                                  60 >
                                  0
                              ? getTimes(lastAssignment.lastSubmittedDate, lastAssignment.lastSubmittedTime)
                              : parseInt(
                                  moment
                                    .duration(
                                      moment("11:59 PM", "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asHours()
                                ) < 0 ||
                                parseInt(
                                  moment
                                    .duration(
                                      moment("11:59 PM", "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asMinutes() + 1
                                ) %
                                  60 <
                                  0
                              ? "Exam has been ended"
                              :
                            "Start" : 
                            parseInt(
                              moment(
                                moment(assignmentName.examDate).format(
                                  "YYYY-MM-DD"
                                )
                              ).diff(moment().format("YYYY-MM-DD"), "days")
                            ) > 0
                              ? getTimes(assignmentName.examDate, examStartTime)
                              : (moment().format("YYYY-MM-DD") ==
                              assignmentName.examDate &&
                                  parseInt(
                                    moment
                                      .duration(
                                        moment(
                                          examStartTime,
                                          "HH:mm:ss a"
                                        ).diff(
                                          moment(
                                            moment().format("LTS").toString(),
                                            "HH:mm:ss a"
                                          )
                                        )
                                      )
                                      .asHours()
                                  ) > 0) ||
                                parseInt(
                                  moment
                                    .duration(
                                      moment(examStartTime, "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asMinutes() + 1
                                ) %
                                  60 >
                                  0
                              ? getTimes(assignmentName.examDate, examStartTime)
                              : parseInt(
                                  moment
                                    .duration(
                                      moment(examEndTime, "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asHours()
                                ) < 0 ||
                                parseInt(
                                  moment
                                    .duration(
                                      moment(examEndTime, "HH:mm:ss a").diff(
                                        moment(
                                          moment().format("LTS").toString(),
                                          "HH:mm:ss a"
                                        )
                                      )
                                    )
                                    .asMinutes() + 1
                                ) %
                                  60 <
                                  0
                              ? "Exam has been ended"
                              :
                            "Start"

                            
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : completedTest ? (
                  isTimeout ? (
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                      <div className="d-flex flex-column">
                        <div>Timeout</div>
                        <div>
                          <KButton
                            onClick={() => {
                              setShowIntructions(true);
                              setCompletedTest(false);
                              setTimeout(false);
                              setSelectedAssignment("");
                              setAssignmentName({
                                subjectName: "",
                                ExamName: "",
                                examDate: "",
                              });
                              setQuestionIndex(0);
                              setDraftCollection([]);
                              setResponseCollection([]);
                              setQuestionDisplay([]);
                              setAnswerEditor([]);
                              fetchData();
                            }}
                            label={"Continue"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center paddingBottom-50">
                      <div>
                        <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                          <img
                            src={CompletedTick}
                            width="150"
                            height="150"
                            alt="finish test"
                          />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <div
                            className="h4 pt-2"
                            style={{ textAlign: "center", lineHeight: "40px" }}
                          >
                            Dear{" "}
                            {JSON.parse(localStorage.userCreds)?.user.fullName},{" "}
                            <br />
                            Your answers has been submitted successfully
                          </div>
                          <KButton
                            onClick={() => {
                              setShowIntructions(true);
                              setCompletedTest(false);
                              setVideoDisplay(false);
                              setSelectedAssignment("");
                              setAssignmentName({
                                subjectName: "",
                                ExamName: "",
                                examDate: "",
                              });
                              setQuestionIndex(0);
                              setDraftCollection([]);
                              setResponseCollection([]);
                              setQuestionDisplay([]);
                              setAnswerEditor([]);
                              // fetchData();
                              logoutfunc();
                            }}
                            className="px-3 py-2 marginTop-10"
                            // label={"Back to ISKCON"}
                            label={"Back to Login"}
                            style={{
                              width: "fit-content",
                            }}
                          />

                          {/* <div  className="pt-3">
                          
                            You Have Scored {
                              answerEditor.reduce((prev,next) =>{
                                  return prev + next.marks
                                },0)
                            } / {(answerEditor.length * 10)}  Marks
                            
      
                        
                          </div> */}
                          {/* <div>
                            Your Bonus Mark 
                            &nbsp;
                            {
                              answerEditor.reduce((prev,next) =>{
                                  return prev + next.bonusMark
                                },0)
                            } / {(answerEditor.length * 3)}
</div> */}
                          <div className="pt-3">
                            Your Score &nbsp;
                            {/* answerEditor.reduce((prev,next) =>{
                                  return prev + next.total
                                },0) */}
                            {answerEditor.reduce((prev, next) => {
                              return prev + Number(next.total);
                            }, 0)}
                            /
                            {answerEditor.length * 10 + answerEditor.length * 3}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div>
                    {/* Cringe Header */}

                    <KHeader
                      style={{
                        justifyContent: "space-between",
                      }}
                      frontElement={
                        <div className="d-flex flex-column">
                          <div className="StudentAssignment__exam-title">
                            {assignmentName?.ExamName}
                          </div>
                          <div className={"SubTitle_label"}>{assignmentName?.subjectName}</div>
                        </div>
                      }
                      containerClassName={`justify-content-front align-items-center`}
                      LastElement={
                        <div className="d-flex flex-row align-items-center">
                          {/* <div style={{ fontSize: "18px", fontWeight: "600" }}>
                            Overall Time:
                          </div>
                          &nbsp;&nbsp;
                          <Timer
                            countRef={counterRef}
                            timer={timer}
                            settingTimer={settingTimer}
                            alert={true}
                          /> */}
                          <KButton
                            btnColor={"green"}
                            style={{
                              height: "fit-content",
                            }}
                            isDisabled={
                              questionDisplay.length > 0 &&
                              answerEditor.length === questionDisplay.length
                                ? false
                                : true
                            }
                            onClick={() => {
                              SubmitExamFunc(
                                selectedAssignment,
                                JSON.parse(localStorage.userCreds).user._id,
                                true
                              );
                              // postAnswer(selectedAssignment)
                            }}
                            className="d-flex flex-row justify-content-center align-items-center px-4 py-2 mr-2"
                            label="Submit"
                          />
                        </div>
                      }
                    />

                    {/* Download Upload Section Starts */}

                    <div className="d-flex flex-row h-100 border-top-d8d8d8">
                      {/* <div className="col-3 w-100 pt-3">
                        <div
                          className="d-flex flex-row"
                          style={{
                            flexWrap: "wrap",
                          }}
                        >
                          {questionDisplay.map((x, i) => (
                            <div
                              onClick={() => {
                                // setQuestionIndex(i);
                              }}
                              className="d-flex justify-content-center align-items-center user-select-none"
                              style={{
                                backgroundColor:
                                  answerEditor.filter((m) => m._id === x._id)
                                    .length > 0
                                    ? answerEditor.find((m) => m._id === x._id)
                                        ?.ignored
                                      ? "white"
                                      : answerEditor.find(
                                          (m) => m._id === x._id
                                        )?.isCorrect
                                      ? "green"
                                      : "#ff0000"
                                    : "white",
                                color:
                                  answerEditor.filter((m) => m._id === x._id)
                                    .length > 0
                                    ? answerEditor.find((m) => m._id === x._id)
                                        ?.ignored
                                      ? "#ff0000"
                                      : "white"
                                    : questionIndex === i
                                    ? "#2684ff"
                                    : "black",
                                border: `1px solid ${
                                  answerEditor.filter((m) => m._id === x._id)
                                    .length > 0
                                    ? answerEditor.find((m) => m._id === x._id)
                                        ?.ignored
                                      ? "#ff0000"
                                      : answerEditor.find(
                                          (m) => m._id === x._id
                                        )?.isCorrect
                                      ? "green"
                                      : "#ff0000"
                                    : questionIndex === i
                                    ? "#2684ff"
                                    : "#d8d8d8"

                                  // questionIndex === i &&
                                  // !(
                                  //   answerEditor.filter((m) => m._id === x._id)
                                  //     .length > 0
                                  // )
                                  //   ? "#2684ff"
                                  //   :
                                  //   answerEditor.find((m) => m._id === x._id)
                                  //   ?.ignored ? "#ff0000" :
                                  //   answerEditor.find((m) => m._id === x._id)
                                  //   ?.isCorrect ? "green" : "#ff0000"

                                  //   :
                                  //    "#d8d8d8"
                                }`,
                                width: "40px",
                                height: "40px",
                                borderRadius: "50px",
                                margin: "3%",
                                // cursor: "pointer",
                              }}
                            >
                              <div>{i + 1}</div>
                            </div>
                          ))}
                        </div>
                      </div> */}
                      <div
                        className="col-12 pt-3"
                        style={{
                          height: window.innerHeight - 150 + "px",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          display: "flex",
                          borderLeft: "1px solid #d8d8d8",
                          fontFamily: 'Nunito',
                          fontSize: "20px",
                          overflow: "auto"
                        }}
                      >
                        <div>
                          {questionDisplay
                            .filter((quesData, indexQues) => {
                              if (indexQues == questionIndex) {
                                return quesData;
                              } else {
                                return null;
                              }
                            })
                            .map((data, index) => (
                              <Fragment key={index}>
                                {/* Question Section Start */}
                                <div
                                  className={`d-flex flex-row w-100 ${
                                    index === 0 ? "" : ""
                                  } ${isBigScreen ? "" : ""}`}
                                >
                                  <div className="d-flex flex-row align-items-center py-2 w-100">
                                    <div className="flex-row col-10">
                                      {/* <b
                                        style={{
                                          maxWidth: "3%",
                                        }}
                                      >
                                        {questionIndex + 1} :{" "}
                                      </b> */}
                                      {parse(data.question)}
                                    </div>
                                    <div className="w-100 h-100">
                                      <div
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Question Time:
                                      </div>
                                      <Timer
                                        countRef={singeQuestionTimerRef}
                                        timer={
                                          answerEditor.filter(
                                            (item) => item._id === data._id
                                          ).length > 0
                                            ? answerEditor.find(
                                                (item) => item._id === data._id
                                              )?.timer
                                            : SingleQuestiontimer
                                        }
                                        settingTimer={
                                          singleQuestionsettingTimer
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Question Section End */}
                                {
                                  // answerEditor.filter(item => item.key === index).length > 0 &&
                                  <Fragment>
                                    {/* Answer Section Start */}
                                    <div
                                      className={`${
                                        isBigScreen ? "paddingLeft-10" : ""
                                      }`}
                                    >
                                      <div className="col-8">
                                        <Fragment>
                                          <Fragment>
                                            {
                                              //                   answerEditor.find(item => item.key === questionIndex)?.answerType === "TextArea" || answerEditor.find(item => item.key === questionIndex)?.answerType === "TextBox" ?
                                              // <KEditor
                                              //   height={500}
                                              //   onChange={value => {
                                              //     AnswerEditorHandler(questionIndex,value)
                                              //   }}
                                              //   value={
                                              //     answerEditor.find(item => item.key === questionIndex)?.value
                                              //       ? answerEditor.find(item => item.key === questionIndex)?.value
                                              //       : ''
                                              //   }
                                              // />
                                              //                     :
                                              data?.answerType ===
                                                "RadioButton" && (
                                                <Fragment>
                                                  {data.options.map(
                                                    (option, i) => (
                                                      <Fragment key={i}>
                                                        <div className="d-flex flex-row">
                                                          <div className="pr-2">
                                                            <input
                                                              type="radio"
                                                              name={data._id}
                                                              disabled={
                                                                answerEditor.filter(
                                                                  (item) =>
                                                                    item._id ===
                                                                    data._id
                                                                )?.length > 0 
                                                                  ? true
                                                                    : false
                                                              }
                                                              checked={
                                                                answerEditor.find(
                                                                  (item) =>
                                                                    item._id ===
                                                                    data._id
                                                                )?.value
                                                                  ? answerEditor.find(
                                                                      (item) =>
                                                                        item._id ===
                                                                        data._id
                                                                    )?.value ===
                                                                    option.option
                                                                    ? true
                                                                    : false
                                                                  : false
                                                              }
                                                              onChange={(e) => {
                                                                RadioButtonAnswerHandler(
                                                                  data._id,
                                                                  option.option,
                                                                  selectedAssignment,
                                                                  data
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div>
                                                            <span>
                                                              {parse(
                                                                option.option
                                                              )}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </Fragment>
                                                    )
                                                  )}
                                                </Fragment>
                                              )
                                            }
                                          </Fragment>
                                        </Fragment>
                                      </div>
                                    </div>
                                    {/* Answer Section End */}
                                  </Fragment>
                                }
                              </Fragment>
                            ))}
                        </div>
                        <div>
                          <div className="d-flex flex-row ">
                            <div></div>
                            <div className="d-flex flex-row ">
                              {/* <div
                                className="px-3 py-2 mr-2"
                                onClick={() => {
                                  if (questionIndex === 0) {
                                  } else {
                                    setQuestionIndex(questionIndex - 1);
                                  }
                                }}
                                style={{
                                  cursor:
                                    questionIndex === 0
                                      ? "not-allowed"
                                      : "pointer",
                                  opacity: questionIndex === 0 ? "0.5" : 1,
                                }}
                              >
                                <BsArrowLeft />
                                &nbsp;Previous
                              </div> */}

                              <div
                                className="px-3 py-2 mr-2"
                                onClick={() => {
                                  if (
                                    questionIndex + 1 ===
                                    questionDisplay.length
                                  ) {
                                  } else {
                                    if (
                                      answerEditor.filter(
                                        (item) =>
                                          item._id === questionIdISDisplaying
                                      ).length !== 0
                                    ) {
                                      const nextAuestion = questionIndex + 1;
                                      setQuestionIdISDisplay(
                                        questionDisplay[nextAuestion]._id
                                      );
                                      setQuestionIndex(nextAuestion);
                                    }
                                  }
                                }}
                                style={{
                                  cursor:
                                    questionIndex + 1 ===
                                      questionDisplay.length ||
                                    answerEditor.filter(
                                      (item) =>
                                        item._id === questionIdISDisplaying
                                    ).length === 0
                                      ? "not-allowed"
                                      : "pointer",
                                  opacity:
                                    questionIndex + 1 ===
                                      questionDisplay.length ||
                                    answerEditor.filter(
                                      (item) =>
                                        item._id === questionIdISDisplaying
                                    ).length === 0
                                      ? "0.5"
                                      : 1,
                                }}
                              >
                                Next&nbsp;
                                <BsArrowRight />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
              </Fragment>
              {/* <FaceFinder /> */}
            </Fragment>
          )}
          {/* </QuestionView> */}
        </div>
        {/* <CModal
          style={{
            backgroundColor: "#FFFFFF",
          }}
          size="lg"
          visible={
            !showIntructions && selectedAssignment !== "" && isFaceVerified
          }
        >
          <CModalBody>
            <div
              className="p-5"
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <div>Capture your face In order to Start the Exam</div>
              <CameraFeed sendFile={uploadImage} />
            </div>
          </CModalBody>
        </CModal> */}
        <CModal
          style={{
            backgroundColor: "#FFFFFF",
          }}
          size="lg"
          visible={
            !completedTest &&
            !showIntructions &&
            selectedAssignment !== "" &&
            isFaceVerified
          }
        >
          <CModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="p-5"
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <h2>
                You will need to stay on camera throughout the test. So please
                show your face in camera
              </h2>
            </div>
          </CModalBody>
        </CModal>
      </div>
      {/* <div style={{
          position : "absolute",
          bottom : 10,
          right : 10
        }}> */}
      {/* </div> */}
      {/* Footer Section Starts */}
      <Footer
      // openForm={openForm}
      // shoq={shoq}
      // closeForm={closeForm}
      // KpostIframe={KpostIframe}
      />
      {/* Footer Section Ends */}
    </div>
  );
};

export default StudentAssignment;
